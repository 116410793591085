// export default function (url) {
//   //   let _url =
//   //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww7a018040d7fb65ec&redirect_uri=" +
//   //     encodeURIComponent(url) +
//   //     "&response_type=code&scope=snsapi_base&state=status&#wechat_redirect";
//   let _url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
//     process.env.VUE_APP_WX_APP_ID
//   }&redirect_uri=${encodeURIComponent(
//     url
//   )}&response_type=code&scope=snsapi_base&state=status&#wechat_redirect`;
//   window.location.replace(_url);
// }

export default function (url) {
  let _url =
    "https://c.test.quyixian.com/Home/IndexBase?reurl=" +
    encodeURIComponent(url);
  window.location.replace(_url);
}
