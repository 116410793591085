<template>
  <div class="recorder">
    <template v-if="fullFile != '' && resList.length > 0">
      <div class="recorder-head">
        <div class="recorder-head-left" @click="back">
          <van-icon name="arrow-left" />
        </div>
        <div class="recorder-head-title">{{ title }}</div>
      </div>
      <!-- 词汇、短句 -->
      <div class="recorder-list">
        <div
          @click="clickItem(index)"
          v-for="(item, index) in resList"
          :key="item.id"
          :class="item.isOpen ? 'recorder-list-item1' : 'recorder-list-item'"
        >
          <template v-if="item.isOpen">
            <div class="box">
              <div class="number">{{ index + 1 }}/{{ resList.length }}</div>
              <div class="beat beat-audio" v-if="item.isReading">
                <ul>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                </ul>
              </div>
              <div class="beat beat-recorded" v-else-if="item.isAudioing">
                <ul>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                </ul>
              </div>
              <div class="beat" v-else>
                <ul>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                  <p class="box-p"></p>
                </ul>
              </div>
            </div>
            <p
              class="title1"
              v-if="item.word"
              v-html="item.evaWord ? item.evaWord : item.word"
            ></p>
            <p
              class="minTitle"
              v-if="item.wordExplain"
              v-html="item.wordExplain"
            ></p>
            <div class="recorder-fun">
              <!--原音部分-->
              <div
                class="recorder-fun-box recorder-fun-audio"
                v-if="item.isReading"
                @click="clickReadPause"
              >
                <van-image
                  height="30"
                  fit="contain"
                  src="https://i.cdn.quyixian.com/53english/horn.png"
                />
                <p>播放中</p>
              </div>
              <template v-else>
                <div
                  class="recorder-fun-box recorder-fun-audio"
                  v-if="item.isRecodering || item.isAudioing"
                >
                  <van-image
                    height="30"
                    fit="contain"
                    src="https://i.cdn.quyixian.com/53english/horn-grey.png"
                  />
                  <p>播放原音</p>
                </div>
                <div
                  class="recorder-fun-box recorder-fun-audio"
                  @click="clickReadPlay"
                  v-else
                >
                  <van-image
                    height="30"
                    fit="contain"
                    src="https://i.cdn.quyixian.com/53english/horn.png"
                  />
                  <p>播放原音</p>
                </div>
              </template>
              <!--录音部分-->
              <div
                class="recorder-fun-box recorder-fun-recording"
                @click="stopRecordAudio"
                v-if="item.isRecodering"
              >
                <van-image
                  height="30"
                  fit="contain"
                  src="https://i.cdn.quyixian.com/53english/recording.png"
                />
                <p>点击停止</p>
              </div>
              <div
                class="recorder-fun-box recorder-fun-recording"
                @click="startRecordAudio(item.isReading, item.isAudioing)"
                v-else
              >
                <van-image
                  height="30"
                  fit="contain"
                  src="https://i.cdn.quyixian.com/53english/recording.png"
                />
                <p>点击录音</p>
              </div>
              <!--录音播放部分-->
              <template v-if="item.userScore && item.userScore > 0">
                <div
                  class="recorder-fun-box recorder-fun-recorded"
                  v-if="
                    item.audioRecordUrl == '' &&
                    (item.isRecodering || item.isReading)
                  "
                >
                  <div class="recorder-fun-recorded-score">
                    {{ item.userScore }}
                  </div>
                  <van-image
                    height="30"
                    fit="contain"
                    src="https://i.cdn.quyixian.com/53english/score.png"
                  />
                  <p>播放录音</p>
                </div>
                <div
                  class="recorder-fun-box recorder-fun-recorded"
                  v-else-if="item.isAudioing"
                  @click="clickItemPauseRecorder"
                >
                  <div class="recorder-fun-recorded-score">
                    {{ item.userScore }}
                  </div>
                  <van-image
                    height="30"
                    fit="contain"
                    src="https://i.cdn.quyixian.com/53english/score.png"
                  />
                  <p>播放中</p>
                </div>
                <div
                  class="recorder-fun-box recorder-fun-recorded"
                  v-else
                  @click="clickItemPlayRecorder"
                >
                  <div class="recorder-fun-recorded-score">
                    {{ item.userScore }}
                  </div>
                  <van-image
                    height="30"
                    fit="contain"
                    src="https://i.cdn.quyixian.com/53english/score.png"
                  />
                  <p>播放录音</p>
                </div>
              </template>
              <div class="recorder-fun-box recorder-fun-recorded" v-else>
                <div
                  class="recorder-fun-recorded-score recorder-fun-recorded-score1"
                >
                  0
                </div>
                <van-image
                  height="30"
                  fit="contain"
                  src="https://i.cdn.quyixian.com/53english/score-grey.png"
                />
              </div>
            </div>
            <!--显示音波-->
            <div
              class="read-box"
              :id="'mxy' + index"
              :ref="'mxy' + index"
            ></div>
          </template>
          <template v-else>
            <div class="recorder-list-item-left">
              <div class="recorder-list-item-left-score">
                {{ item.userScore == null ? "0" : item.userScore }}
              </div>
              <van-image
                height="30"
                fit="contain"
                src="https://i.cdn.quyixian.com/53english/score-grey.png"
              />
            </div>
            <div class="recorder-list-item-right">
              <p
                class="title1"
                v-html="item.evaWord ? item.evaWord : item.word"
              ></p>
              <p
                class="minTitle"
                v-if="item.wordExplain"
                v-html="item.wordExplain"
              ></p>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="recorder-no" v-else>
      <van-loading type="spinner" vertical>{{ loadingText }}</van-loading>
    </div>
    <audio id="audio" ref="audio" style="height: 0" :src="fullFile"></audio>
    <audio
      id="partAudio"
      ref="partAudio"
      style="height: 0"
      :src="audioCurrFile"
    ></audio>
    <div id="fixed" class="recorder-fixed">
      <canvas id="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import Recorder from "js-audio-recorder";
import Wx from "@/assets/js/wx";

export default {
  name: "read",
  data() {
    return {
      rid: this.$route.query.rid == undefined ? "" : this.$route.query.rid,
      rname:
        this.$route.query.rname == undefined ? "" : this.$route.query.rname,
      bid: this.$route.query.bid == undefined ? "" : this.$route.query.bid,
      bname:
        this.$route.query.bname == undefined ? "" : this.$route.query.bname,
      type: this.$route.query.type == undefined ? "3" : this.$route.query.type,
      title:
        this.$route.query.title == undefined
          ? "句子跟读"
          : this.$route.query.title,
      userid: this.$cookie.getCookie("userid"),
      code: this.$route.query.code,
      baseHref: window.location.href, //当前页面URL
      //
      loading: false, //加载状态
      loadingText: "加载中...", //加载状态文字
      fullFile: "", //全文音频
      resList: [],
      audioCurrFile: "", //当前段落文件
      audioCurrNumber: 0, //当前点击序号
      audioStart: 0, //段落开始时间
      audioEnd: 0, //段落结束时间
      audioEndAdd: 0.25, //结束框量
      //录音对象
      rec: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
      }),
      //波浪图-录音
      drawRecordId: null,
      oCanvas: null,
      ctx: null,
      fileOfWav: "", //存储录音文件
    };
  },
  watch: {
    rec: {
      handler: (nVal, oVal) => {
        // if (nVal.duration >= 60) {
        //   Toast("太长了自动停止");
        //   this.stopRecordAudio();
        // }
      },
      deep: true,
    },
    fullFile: {
      handler: (nVal, oVal) => {
        let audio = document.getElementById("audio");
        setTimeout(() => {
          audio.load();
          audio.currentTime = 0;
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    const that = this;
    //this.getReadList();
    //this.userid = "oKw74t6VnO8Kh1Wru9sI8EvFNpts";
    //登录校验
    if (!this.userid) {
      if (this.code) {
        //接口 code换openid
        this.getuserid();
        //获取相关
        this.getReadList();
      } else {
        Wx(this.baseHref); //静默授权
      }
    } else if (this.rid) {
      //获取相关
      this.getReadList();
    } else {
      Dialog.alert({
        title: "未找到相关数据",
        message: "抱歉！数据可能走丢了，请退出，重新进入",
      }).then(() => {
        window.history.go(-1);
        //window.location.go(-1)
        //history.back(-1) 后退
      });
    }

    //尝试获取一次录制权限
    Recorder.getPermission().then(
      () => {},
      (error) => {
        Toast("无录制权限");
      }
    );
    //
    this.oCanvas = document.getElementById("canvas");
    this.ctx = this.oCanvas.getContext("2d");

    //监听全文播放音频
    document
      .getElementById("audio")
      .addEventListener("timeupdate", function () {
        let current = this.currentTime; //当前时间
        let duration = this.duration; //总时间
        let end = parseFloat(that.audioEnd + that.audioEndAdd);
        if (that.audioEnd > 0 && (end <= current || current >= duration)) {
          this.pause();
          that.resList[that.audioCurrNumber].isReading = false;
        }
      });
    //监听录音播放音频
    document
      .getElementById("partAudio")
      .addEventListener("timeupdate", function () {
        if (this.currentTime >= this.duration) {
          that.resList[that.audioCurrNumber].isAudioing = false;
        }
      });
  },
  methods: {
    back() {
      history.back();
    },
    //列表跟读切换
    clickItem(index) {
      if (this.resList[index].isOpen == false) {
        for (let i = 0; i < this.resList.length; i++) {
          this.resList[i].isOpen = false;
          //操作当前节点
          if (i == index) {
            this.resList[i].isOpen = true;
            //console.log(this.resList[i]);
            this.audioCurrNumber = index; //记录当前点击的列表序列
            this.audioStart = this.resList[i].startTime;
            this.audioEnd = this.resList[i].endTime;
            console.log("start:" + this.audioStart + ",end:" + this.audioEnd);
          }
        }
        //播放原音
        this.toReadPlay(true);
      }
    },
    //暂停播放原音
    clickReadPause() {
      let audio = this.$refs.audio;
      audio.pause();
      //更改朗读状态
      this.resList[this.audioCurrNumber].isReading = false;
    },
    //播放，暂停---朗读音频文件/已选中二次点读
    clickReadPlay() {
      this.toReadPlay(false);
    },
    toReadPlay(state) {
      //更改朗读状态
      this.resList[this.audioCurrNumber].isReading = true;
      //
      let audio = this.$refs.audio;
      audio.pause();
      audio.volume = 1;
      if (this.isAndroid()) {
        audio.currentTime = this.audioStart;
      } else {
        document.getElementById("audio").currentTime = this.audioStart;
      }
      //IOS播放故障
      // if (state || (!state && parseFloat(this.audioEnd) <= audio.currentTime)) {
      //   audio.currentTime = this.audioStart;
      // }
      console.log(
        "state:" +
          state +
          ",audioEnd:" +
          parseFloat(this.audioEnd) +
          ",currentTime:" +
          audio.currentTime
      );

      setTimeout(() => {
        audio.play();
      }, 500);
    },
    //已选中-开始播放录音
    clickItemPlayRecorder() {
      this.audioCurrFile = this.resList[this.audioCurrNumber].audioRecordUrl; //装入文件
      //"https://res.cdn.quyixian.com/OralEvaluate/1686536675210_1686536676647.wav"

      if (this.audioCurrFile) {
        this.resList[this.audioCurrNumber].isAudioing = true;
        let partAudio = this.$refs.partAudio;
        partAudio.volume = 1;
        partAudio.pause();
        setTimeout(() => {
          partAudio.play();
        }, 100);
      } else {
        Toast("未找到录音");
      }
    },
    //已选中-停止播放录音
    clickItemPauseRecorder() {
      let partAudio = this.$refs.partAudio;
      partAudio.pause();
      //更新状态
      this.resList[this.audioCurrNumber].isAudioing = false;
    },
    //开始录音
    startRecordAudio(isreading, isaudioing) {
      let that = this;
      if (isreading) {
        that.clickReadPause();
      }
      if (isaudioing) {
        that.clickItemPauseRecorder();
      }
      Recorder.getPermission().then(
        () => {
          //拿到指定点开位置
          document
            .getElementById("mxy" + this.audioCurrNumber)
            .appendChild(document.getElementById("canvas"));
          this.rec.start(); //开始录音
          Toast("开始录音");
          setTimeout(() => {
            that.resList[that.audioCurrNumber].isRecodering = true; //切换录音状态-开始录音
            that.drawRecord(); //开始绘制图片
          }, 500);
        },
        (error) => {
          //提示
          Dialog.alert({
            title: "网页授权",
            message: "请先允许该网页使用麦克风",
            confirmButtonText: "刷新",
          }).then(() => {
            window.location.replace(window.location.href);
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    //停止录音
    stopRecordAudio() {
      this.rec.stop();
      //停止绘制
      this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
      this.drawRecordId = null;
      // fixed-放回去
      document
        .getElementById("fixed")
        .appendChild(document.getElementById("canvas"));
      //切换录音状态-停止录音
      this.resList[this.audioCurrNumber].isRecodering = false;
      //朗读中禁止播放段落录音
      this.resList[this.audioCurrNumber].isAudioing = false;
      console.log("停止录音");
      Toast("识别中...");
      //处理数据
      this.uploadWAVData();
    },
    //上传wav录音数据
    uploadWAVData() {
      let that = this;
      let wavBlob = this.rec.getWAVBlob();
      let formData = new FormData();
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: "audio/wav" });
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      this.fileOfWav = fileOfBlob;
      formData.append("file", fileOfBlob);
      //1接口上传文件
      this.$api.res.uploadFile(formData).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          //保存用户当前上传外网的录音文件
          this.resList[this.audioCurrNumber].audioRecordUrl = data;
          //2接口评测打分
          this.ora();
        } else {
          console.log(message);
          Toast.fail("上传录音失败");
        }
      });
    },
    ora() {
      this.$api.res
        .ora({
          oraltext: this.resList[this.audioCurrNumber].word,
          audioRecordUrl: this.resList[this.audioCurrNumber].audioRecordUrl,
        })
        .then((res) => {
          let { tag, data, message } = res;
          if (tag == 1) {
            //操作数据集
            this.resList[this.audioCurrNumber].userScore = data.score;
            this.resList[this.audioCurrNumber].evaWord = data.oralText;
            //3保存用户得分
            this.saveUserRead();
          } else {
            console.log(message);
            Toast.fail("识别录音失败");
          }
        });
    },
    //保存用户跟读数据
    async saveUserRead() {
      let formData = new FormData();
      formData.append("userid", this.userid);
      formData.append("bookid", this.bid);
      formData.append("bookname", this.bname);
      formData.append("resourceid", this.rid);
      formData.append("resourceName", this.rname);
      formData.append("followreadid", this.resList[this.audioCurrNumber].id);
      formData.append(
        "followreadword",
        this.resList[this.audioCurrNumber].word
      );
      formData.append("evaword", this.resList[this.audioCurrNumber].evaWord);
      formData.append("score", this.resList[this.audioCurrNumber].userScore);
      formData.append("file", this.fileOfWav); //保存录音文件到OSS
      formData.append(
        "audioRecordUrl",
        this.resList[this.audioCurrNumber].audioRecordUrl
      ); //保存当前用户录音上传回URL地址
      const { tag, data, message } = await this.$api.res.saveUserScore(
        formData
      );
      if (tag == 1) {
      } else {
        Toast(message);
      }
    },
    //绘制波浪图-录音
    drawRecord() {
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord);

      // 实时获取音频大小数据
      let dataArray = this.rec.getRecordAnalyseData(),
        bufferLength = dataArray.length;

      // 填充背景色
      this.ctx.fillStyle = "rgb(244, 244, 244)";
      this.ctx.fillRect(0, 0, this.oCanvas.width, this.oCanvas.height);

      // 设定波形绘制颜色
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "rgb(156,156,156)";

      this.ctx.beginPath();

      var sliceWidth = (this.oCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
        x = 0; // 绘制点的x轴位置

      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * this.oCanvas.height) / 2;

        if (i === 0) {
          // 第一个点
          this.ctx.moveTo(x, y);
        } else {
          // 剩余的点
          this.ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }

      this.ctx.lineTo(this.oCanvas.width, this.oCanvas.height / 2);
      this.ctx.stroke();
    },
    //获取登录信息
    async getuserid() {
      let params = {
        code: this.code,
      };
      const { data, tag, message } = await this.$api.res.getOpenid(params);
      if (tag == 1) {
        this.userid = data;
        this.$cookie.clearCookie("userid");
        this.$cookie.setCookie("userid", data);
      } else {
        Toast(message);
      }
    },
    //获取跟读数据
    async getReadList() {
      let params = {
        resid: this.rid,
        datatype: this.type,
        userid: this.userid,
      };
      const { data, tag, message } = await this.$api.res.getFollowList(params);
      if (tag == 1) {
        if (data.filePath == "" || data.followList.length == 0) {
          this.loadingText = "无内容...";
        }
        this.loading = true;
        document.getElementById("audio").load();
        document.getElementById("audio").currentTime = 0;
        this.fullFile = data.filePath + "?t=" + Date.now();
        this.resList = data.followList;
        console.log(this.resList);
      } else {
        Toast(message);
      }
    },
    isAndroid() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        return true;
      }
    },
  },
};
</script>

<style></style>
